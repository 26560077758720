require.config({
    paths: {
        jquery: '../vendor/jquery/1.10.2/jquery-1.10.2.min',
        bootstrap: '../vendor/bootstrap/3.0.17/js/sass-bootstrap.min',
        lazyload: '../vendor/lazyload/lazy.load.min',
        crossroads: '../vendor/crossroads/crossroads.min',
        signals: '../vendor/crossroads/signals.min',
        owlcarousel: '../js/owl.carousel.min',
        countdown: '../js/jquery.countdown',
        elevatezoom: '../js/plugins/jquery.elevatezoom',
        magnific: '../js/plugins/magnific',
        jqueryui: '../vendor/jquery-ui/1.10.3/full/js/jquery-ui-1.10.3.custom.min',
        chosen: '../vendor/chosen/chosen.jquery.min',
        slidesjs: '../vendor/slidesjs/jquery.slides.min'
    },
    shim: { 
        bootstrap: {
            deps: ["jquery"]
        }
    }
});

require(['jquery', 'bootstrap', 'lazyload', 'crossroads', 'signals','owlcarousel', 'countdown', 'elevatezoom', 'magnific','jqueryui', 'chosen', 'slidesjs'], function ($, Bootstrap, lazyload, crossroads, signals, owlcarousel, countdown, elevatezoom, magnific, jqueryui, chosen, slidesjs) {

    var isTouchDevice = function() {  return 'ontouchstart' in window || 'onmsgesturechange' in window; };
    var isDesktop = window.screenX != 0 && !isTouchDevice() ? true : false;
    
    var oldIE;
    if ($('html').is('.ie6, .ie7, .ie8, .ie9')) {
        oldIE = true;
    }
    
    var eventTrigger;
    $('.chzn-select').chosen({disable_search: true});
    
    $('.dropdown-toggle').dropdown();
    $('.btn-toolbar .btn').tooltip({
        
        container: 'body'
        
    });
    alignTopLinks(); 
     
    
    if(getCookie('showpopover') === 'noshow'){
        // should not do anything here
    } else {
       if($('#popover-login').length){
            $.magnificPopup.open({
                 items: {
                     src: $('#popover-login'), // Dynamically created element
                     type: 'inline'
                 },
                 type: 'inline'
             }); 
        }
    }
    
    $('.login').submit(function(e){
       $.ajax({
            type: "POST",
            url: "/login/validateloginform",
            data: $(this).serialize(),
            cache: false,
            success: function(json){
                if(json == 'success'){
                    $.magnificPopup.close();
                    location.reload(); 
                } else {
                
                    $('.login .text-danger').remove();
                    var string = '';
                    $.each(json, function(i, item) {
                        var obj = item;
                        var string = obj[Object.keys(obj)[0]]; 

                        $('#'+i).after('<p class="text-danger">'+string+'</div>');
                    });
                }
            }
        }); 
        e.preventDefault();
    });
    
    $(document).on('click', '.enquire .input-submit',function(e){
       $.ajax({
            type: "POST",
            url: "/login/validateenquiryform",
            data: $(this).closest('form').serialize(),
            cache: false,
            success: function(json){
                if(json === 'success'){
                    $('.bg-success').remove();
                    $('.enquire').before('<p class="bg-success">Success, your mail has been sent</p>').delay(1000).hide();
                    $('.enquire').find('input[type=text]').val('');
                    $('.enquire').find('textarea').val('');
            
                    $('.enquire .text-danger').remove();
                    $('.mfp-wrap .enquire').show(); 
                } else {
                    $('.enquire .text-danger').remove();
                    $('.bg-success').remove();
                    var string = '';
                    $.each(json, function(i, item) {
                        var obj = item;
                        var string = obj[Object.keys(obj)[0]]; 

                        $('#'+i).after('<p class="text-danger">'+string+'</div>');
                    });
                }
            }
        }); 
        e.preventDefault();
    });
    
    $(document).on('click', '.loginover .input-submit',function(e){    
        
        var hrefReturn = $('.loginover form input#return').val();         
        $.ajax({
            type: "POST",
            url: "/login",
            data: $(this).closest('form').serialize(),
            cache: false,
            dataType: "json",
            success: function(json){
                $('.loginover .alert').remove();
                if(json.success == 0){
                     $('.loginover form').before(json.message);
                } else {
                    if(hrefReturn){
                        window.location.replace(hrefReturn);
                    } else {
                        location.reload();
                    }
                }
            }
        }); 
        e.preventDefault();
    });
    
    
    
    $(document).on('click', '.close-continue-browsing', function(){
        setCookie('showpopover', 'noshow', 10);
        $.magnificPopup.close();
    });
    
   function setCookie(c_name,value,minutes)
    {
      var expiryTime = new Date();
      expiryTime.setMinutes(expiryTime.getMinutes() + minutes);
      var c_value=escape(value) + 
        ((minutes==null) ? "" : ("; expires="+expiryTime.toUTCString()));
      document.cookie=c_name + "=" + c_value+';path=/';
    }

    function getCookie(c_name)
    {
     var i,x,y,ARRcookies=document.cookie.split(";");
     for (i=0;i<ARRcookies.length;i++)
     {
      x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
      y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
      x=x.replace(/^\s+|\s+$/g,"");
      if (x==c_name)
      {
       return unescape(y);
      }
     }
    }
    
    
    $(document).on('click', '.enquire9', function(e){
        $('.ov-product-name').text($(this).attr('data-product-name'));
        $('#product_code').val($(this).attr('data-product-id'));
        $('.enquire .text-danger').remove();
        $('.enquire-overlay .bg-success').remove();
        $('.enquire').show(); 
                    
        $.magnificPopup.open({
            items: {
                src: $('.enquire-overlay'), // Dynamically created element
                type: 'inline'
            },
            type: 'inline'
        });
    });
    $(document).on('click', '.enq-overlay', function(e){
        if($(this).attr('data-path')){
            var src = $(this).attr('href')+'?return='+$(this).attr('data-path'); 
        } else {
            var src = $(this).attr('href'); 
        }
        $.magnificPopup.open({
            items: {
                src: src, // Dynamically created element
                type: 'ajax'
            },
            type: 'ajax',
            closeOnContentClick: false,
            closeOnBgClick: false
        });
        e.preventDefault(); 
    }); 

    


 
    /* images on product pages */
    
    $('.hg-l-d').on('click', function () {
        $(this).next().magnificPopup('open');
    });

    
    $('.hg-l').each(function () {
        $(this).magnificPopup({
          delegate: 'a.image-linker',
          type: 'image',
          tLoading: 'Loading image #%curr%...',
          mainClass: 'mfp-img-mobile',
          closeBtnInside: true,
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
          },
          image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            /*titleSrc: function(item) {
              return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
            }*/
          }
        });
    }); 
    

    $("#img_01").elevateZoom({
        easing : true,
        gallery:'gallery_01',
        cursor: 'pointer',
        galleryActiveClass: 'active',
        imageCrossfade: true
    });
    
    $("#img_01").bind("click", function(e) { 
        var ez = $('#img_01').data('elevateZoom');	
        $.magnificPopup.open({
            items: ez.getGalleryListCustom(),
            type: 'inline',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
            }
        });
        e.preventDefault(); 
    }); 

    jQuery(".thumbs-container a").bind("click", function(e) {  
        var id = $(this).attr('id');
        if(id == 'zip-download'){
            window.location.href = $(this).attr('href');
        } else {
            var filename = $(this).next('.filename')[0].outerHTML;
            $('#download-link').html(filename);
            $('#download-link a').removeClass('hide');

            var myVar = setInterval(function(){ // wait for fading
                var height = jQuery('.zoomWrapper').children('img').height();
                jQuery('.zoomWrapper').height(height); 
                clearInterval(myVar);
            }, 200);
        } 
    });
    
     $(".tab-carousel").owlCarousel({
        navigation : true,
        navigationText : ['<span class="c-icon c-prev-caret"></span>','<span class="c-icon c-next-caret"></span>']
    });
    
    $("#owl-carousel1").owlCarousel({
        itemsCustom: [[100,1],[600,2],[900,3],[1000,3], [1125,4], [1200,5], [1600,6], [2000,8]],
        navigation : true,
        navigationText : ['<span class="c-icon c-prev-caret"></span>','<span class="c-icon c-next-caret"></span>'],
        responsive: false,
        afterUpdate: function(elem){
            //  var imageHeightCarousel1 = $('#owl-carousel1 img').height() + 10; 
            //  var imageWidthCarousel1 = $('#owl-carousel1 img').width(); 
            //  $('.carousel-info-box').height(imageHeightCarousel1).width(imageWidthCarousel1);
        }
    });
    
    
    
      /* sets initail height of carousel box */
    var imageHeightCarousel1 = $('#owl-carousel1 img').height() + 10;
    var imageWidthCarousel1 = $('#owl-carousel1 img').width(); 
    $('.carousel-info-box').height(imageHeightCarousel1).width(imageWidthCarousel1);
    

    $("#owl-carousel2").owlCarousel({
        navigation : true,
        navigationText : ['<span class="c-icon c-prev-caret"></span>','<span class="c-icon c-next-caret"></span>']
    });
    $("#owl-carousel3").owlCarousel({
        navigation : true,
        navigationText : ['<span class="c-icon c-prev-caret"></span>','<span class="c-icon c-next-caret"></span>']
    });
    $("#owl-carousel4").owlCarousel({
        navigation : true,
        navigationText : ['<span class="c-icon c-prev-caret"></span>','<span class="c-icon c-next-caret"></span>']
    });
    
    
    

    $("#home-featured").owlCarousel({
        navigation : true,
        itemsDesktop : [1000,5], //5 items between 1000px and 901px
        itemsDesktopSmall : [900,3], // betweem 900px and 601px
        itemsTablet: [600,2], 
        itemsMobile: [479,1],
        navigation : true,
        navigationText : ['<span class="c-icon c-prev-caret"></span>','<span class="c-icon c-next-caret"></span>']
    }); 
      $(document).on('click', '.yamm .dropdown-menu', function(e) {
          e.stopPropagation()
        })
    
    
    $("#hero").slidesjs({
        width: 940,
        height: 437,
        navigation: {
          effect: "fade"
        },
        pagination: {
          effect: "fade"
        },
        effect: {
          fade: {
            speed: 800
          }
        },
        play: {
            auto: true, 
            interval: 7000,
            effect: "fade"
        }
    });
    
    
    
    var owl = $(".single");
    owl.owlCarousel({
        navigation : true,
        singleItem : true,
        autoHeight : true,
        navigationText : ['<span class="c-icon c-prev-caret"></span>','<span class="c-icon c-next-caret"></span>']
    });
    
    
    
    $('#myTab a').click(function (e) {
        e.preventDefault()
        $(this).tab('show');
    });
    
    
    
    $('.popup-gallery').magnificPopup({
        delegate: 'a', // child items selector, by clicking on it popup will open
        type: 'image',
        gallery:{enabled:true}
        // other options
    });

    

    // Icon Click Focus
	$('div.icon').click(function(){
		$('input#search').focus();
	});
    
    
    $('.yourCountdownContainer').countdown({
        date: $('.count-down-date').text(),
        render: function(data) {
            $(this.el).html("<span class='time-left'>TIME LEFT</span>"+this.leadingZeros(data.hours, 2) + "<span>H</span> "+ this.leadingZeros(data.min, 2) + "<span>M</span> " + this.leadingZeros(data.sec, 2) + "<span>S</span>");
        },
        onEnd: function() {
          // reload containerv   
            
          $(this.el).addClass('ended');
        }
    });

	// Live Search
	// On Search Submit and Get Results
    function search() {
        var query_value = $('input#search').val();
        $('#search-string').html(query_value);
        $('input#f-q').val(query_value);
        
        var href = getUrl(); 
        if (!oldIE) {
            history.pushState(null, null, href);
        }
        $('#productListContainer .product-list').html('Searching..');
        eventTrigger = 'search';
        loadProductList(href); 
        return false;    
    }
    function search2() {
        var query_value = $('input#search-qs').val();
        $('#search-string').html(query_value);
        var href = getUrl()+'&q='+query_value; 
        if(query_value !== ''){
            $('#productListContainer .product-list').html('Searching..');
            loadProductList(href); 
        } return false;    
    }
    
    
    $(document).on('click', '.clear-quick-search', function(e) {
        $('#search').val('');
        $('.product-list').empty().html('<p>Start typing above to search.</p>');
        
    }); 
        
        
    function searchRange() {
        var query_value = $('input#searchrange').val();
        var sortby = $('select#sortby').val();
        $('#search-string').html(query_value);
        overlay(); 
        $.ajax({
            type: "POST",
            url: "/product/searchrange",
            data: { query: query_value, sortby:sortby },
            cache: false,
            success: function(html){
                $("#results").html(html);
                overlayClose(); 
                $("img.lazy").lazyload({ threshold : 600 });
            }
        });
        
        return false;    
    } 
    $(document).on('change', '.filter-options-range', function(e) {
        searchRange(); 
    }); 
    
    
    
    $("input#searchrange").on("keyup", function(e) {
		// Set Timeout
        clearTimeout($.data(this, 'timer'));

        // Set Search String
        var search_string = $(this).val();
        $('#search-string').html(search_string);
        
        $("#results").fadeIn();
        $('h4#results-text').fadeIn();
        $(this).data('timer', setTimeout(searchRange, 1000));

    });
    
    $("input#search-qs").on("keyup", function(e) {
	// Set Timeout
        clearTimeout($.data(this, 'timer'));
        // Set Search String
        var search_string = $(this).val();
        $('#search-string').html(search_string);
        // Do Search
        
        if (search_string == '') {
            $('.product-list').empty().html('<p>Start typing above to search.</p>');
        } else {
            $(this).data('timer', setTimeout(search2, 400));
        }
        //if (search_string == '') {
       //     $("#results").fadeOut();
       //     $('h4#results-text').fadeOut();
       // } else{
            //$("#results").fadeIn();
           // $('h4#results-text').fadeIn();
            
       // };

    });
    
    $("input#search").on("keyup", function(e) {
	// Set Timeout
        clearTimeout($.data(this, 'timer'));
        // Set Search String
        var search_string = $(this).val();
        $('#search-string').html(search_string);
        // Do Search
        $(this).data('timer', setTimeout(search, 400));

    });
    
    $.widget( "custom.catcomplete", $.ui.autocomplete, {
        _renderMenu: function( ul, items ) {
          var that = this,
            currentCategory = "";
          $.each( items, function( index, item ) {
            if ( item.category != currentCategory ) {
              ul.append( "<li class='ui-autocomplete-category'>" + item.category + "</li>" );
              currentCategory = item.category;
            }
            that._renderItemData( ul, item );
          });
        }
      });
      
      
    // For ie8. 
    $('.header-search label').on('click', function(){
        if($(this).hasClass('cb-checked')){
            $(this).removeClass('cb-checked');
        } else {
            $(this).addClass('cb-checked');
        }
    });
  
     $(function() {
        $( "#header-search" ).catcomplete({
            //source: "/product/alist",
            source: function( request, response) {
                
                var products = 0;
                var ranges = 0;
                
                if($("#ranges").is(':checked'))
                    var ranges = 'on'; 
                
                 if($("#products").is(':checked'))
                    var products = 'on'; 
                
                
                $.ajax({
                    url: "/product/alist",
                    dataType: "json",
                    data: {
                        q: request.term,
                        ranges: ranges,
                        products: products,
                        qs: true
                    },
                    success: function( data ) {
                       response( data );
                    }
                });
            },
            
            minLength: 1,
            delay: 0,
            select: function( event, ui ) {
                if(ui.item.category == 'range'){
                    window.location.replace('/product/list/category/' + ui.item.id);
                } else {
                    window.location.replace('/product/view/' + ui.item.id);
                }
            }
        });
     });
    
    

    function addToBasket(data) {
	    
        $.ajax({
            type: "POST",
            url: "/basket/add",
            data: data.serialize(),
            cache: false,
            success: function(html){
                $(data).children('.add-to-basket-button').val('Added!');
            }
        });
		 
	}
    
    $(document).on('submit', '.add-item-to-basket', function(e) {
        addToBasket($(this)); 
        e.preventDefault(); 
    });
    
    
    $(document).on('click', '.init-print', function(e) {
        window.print();
        e.preventDefault(); 
    });
    $(document).on('click', '.back-link', function(e) {
        history.back();
        return false; 
    });
    

    $(".form-qsc").submit( function(e) {
        search();
        e.preventDefault(); 
    });
    ///credit/product/list
    
    $(".form-qsc-credit").submit( function(e) {
        var query_value = $('input#searchqsccredit').val();
        $('#search-string').html(query_value);
        var serializedForm = $('form.filter-form').serialize();
        var href = '/credit/product/list?q='+query_value; 

        if(query_value !== ''){
            $('#productListContainer .product-list').html('Searching..')
            loadProductList(href); 
        } return false;
    });
    
    
     function searchCreditList(){
        var query_value = $('input#searchqsccredit').val();
        $('#search-string').html(query_value);
        var serializedForm = $('form.filter-form').serialize();
        var href = '/credit/product/list?q='+query_value;
        if(query_value !== ''){
            $('#productListContainer .product-list').html('Searching..')
            loadProductList(href); 
        } return false;
    }
    
    $("input#searchqsccredit").on("keyup", function(e) {
        var query_value = $('input#searchqsccredit').val();
        if (query_value == '') {
            $('.product-list').empty().html('<p>Start typing above to search.</p>');
        } else {
            $(this).data('timer', setTimeout(searchCreditList, 400));
        }
    });
    /*
     * Remove image from credit return list
     */
    
    $(document).on('click', '#images-container .btn-link', function(e) {
        var button = $(this);
        $.ajax({
            type: "POST",
            url: '/member/credit/deleteimage',
            data: $(this).parent().serialize(),
            success: function (data) {
                $(button).parent().prev('img').parent().remove(); 
            }
        });
        e.preventDefault(); 
    });
   
    
    
    
     /* end  of quick search functions */
     
     $(document).on('click', '.remove-basket-item', function(e) {
            var thisButton = this;
            $.ajax({
                type: "POST",
                url: '/basket/remove',
                data: $(this).parent().serialize()+ "&format=json",
                dataType:  'json',
                success: function (data) {
                    $(thisButton).closest('tr').remove();
                    //alert('Prices and basket to be updated. ');
                }
            });
            return false; 
     }); 
     
     
     
     
    $('.quick-view').magnificPopup({
        type: 'ajax',
        closeOnContentClick:false
    }); 
     
    /*$(document).on('click', '.quick-view', function(e) {
        
     

        
        var href = $(this).attr('href'); 
        $('.quick-view-container').remove();
        $.get( href, function(data) {
            $('body').append(data);
            $('#QuickView').modal({show:true});
            
            jQuery("#img_01").elevateZoom({
                easing : true,
                gallery:'gallery_01',
                cursor: 'pointer',
                galleryActiveClass: 'active',
                imageCrossfade: true,
                responsive: true
            });
        });
        e.preventDefault(); 
    }); */
    
    
    /*$(document).on('click', '.quick-view-container .addToOrder', function(e) {
        var $form = $(this).closest('form');
        var formValues = $($form).serializeArray();
        
        console.log(formValues[2].value); 
        console.log('hitme');
        
        var orderQuantity = formValues[1].value; 
        if(orderQuantity > 0){

            $.ajax({
                type: "POST",
                url: '/basket/add?format=html&c=f',
                data: $form.serialize(), //#!!!!!!!
                success: function(data2) {
                    $.magnificPopup.open({
                        items: {
                            src: $(data2), // Dynamically created element
                            type: 'inline'
                        },
                        type: 'inline'
                    });
                    checkItemsInBasket(); 
                },
                dataType: 'html'
            });
        } 
        e.preventDefault(); 
        
    });*/ 

    function initProductOverlayAndButtons(){
        $('.product-overlay').on('mousemove', (function(e) {
            // console.log("TESTING!!!")
            var $this = $(this).parent().find(".product-images-wrapper")
        
            // Error handling for no child elements
            if ($this.children('.product-image').length === 0) {
                return;
            }
        
            // Calculate relative cursor position
            var offset = $this.offset();
            var relativeX = (e.pageX - offset.left);
            var width = $this.width();
            var x = relativeX / width * 100;
        
            // Calculate image index and change active image
            var imageCount = $this.children('.product-image').length;
            var imageIndex = Math.floor(imageCount * x / 100);
            if (imageIndex >= imageCount) {
                imageIndex = imageCount - 1;
            }
    
            $this.children('.product-image').removeClass('active');
            $this.children('.product-image').eq(imageIndex).addClass('active');
        
        })).on('mouseleave', function() {
            $(this).parent().find(".product-images-wrapper").children('.product-image').removeClass('active');
            $(this).parent().find(".product-images-wrapper").children('.product-image').eq(0).addClass('active');
        });
        
        $(document).on('click', '.addToShortlist', function(e) {
            e.preventDefault();
        });
        $(document).on('click', '.removeFromShortlist', function(e) {
            e.preventDefault();
        });
        
        
        $(document).on('click', '.save-for-later', function(e) {
            var $form = $(this).find("form")
            var button = $(this).find("input[type=image]");
            var url = $form.attr('action');
    
            var isFilled = false;
            if ($(button).hasClass('removeFromShortlist')) {
                isFilled = true;
            }
            
            $.ajax({
                type: "POST",
                url: url,
                data: $form.serialize()+'&format=html', 
                success: function(data) {
                    if (isFilled) {
                        $(button).attr("src","/img/f/hearticon.svg").attr("class","addToShortlist");
                    }else{
                        $(button).attr("src","/img/f/hearticonfill.svg").attr("class","removeFromShortlist");
                    }
                    checkItemsInBasket();
                },
            });
            e.preventDefault(); 
            
        }); 
        
        $(document).on('click', '.close-shortlist-modal', function(e) {
            $('.shortlist-modal-container').remove(); 
        });
    }

    initProductOverlayAndButtons();

         
    if($('.inline-content').length){
        $.colorbox({width:"50%", inline:true, href:".inline-content", escKey: false, overlayClose: false, transition:"none" });
    }
    

    /*$('form.login .input-submit').click(function(){
            $thisForm = $('form.login');
            $.ajax({
                type: "POST",
                url: '/login',
                data: $($thisForm).serialize()+ "&format=json",
                dataType:  'json',
                success: function (data) {
                    if(data.success == 1){
                        // colorbox close
                        $.colorbox.close();
                        location.reload();
                    } else {
                        $($thisForm).before(data.message); 
                    }
                }
            });
            return false; 
     }); */
    
    function overlay(){
        $.magnificPopup.open({
            items: {
              src: '<div style="width:100%; text-align:center;"><img src="/img/f/719.GIF" alt=""/></div>', // can be a HTML string, jQuery object, or CSS selector
              type: 'inline'
            },
            showCloseBtn: false,
            closeOnContentClick: false,
            closeOnBgClick:false
          });
    }
    
    function overlayClose(){
        $.magnificPopup.close();
    }
    
    
    function loadBasket(task){
        if(task != 'no-overlay'){
            overlay(); 
        }
        //Retrieve all the 'get' parameters from the url
        var url = window.location.href;
        var params = url.split('?');
       
        let ajaxUrl = '/basket';

        //add all the parameters to the ajax url
        if(params.length > 1){
            ajaxUrl += '?' + params[1];
        }

        $.get( ajaxUrl, function( data ) {
            if(task != 'no-overlay'){
                overlayClose();
            }
            $('#checkout, .shortlist-carousel,.previouslyordered-carousel').remove(); 
            $("#checkoutContainer").append(data);
            $('.quick-view').magnificPopup({
                type: 'ajax'
            }); 
            $("#owl-carousel1").owlCarousel({
                navigation : true,
                navigationText : ['<span class="c-icon c-prev-caret"></span>','<span class="c-icon c-next-caret"></span>']
            });
            $("#owl-carousel4").owlCarousel({
                navigation : true,
                navigationText : ['<span class="c-icon c-prev-caret"></span>','<span class="c-icon c-next-caret"></span>']
            });
        });
        
    }

    if($('#checkoutContainer').length){
        loadBasket(); 
    }
    
    if (!oldIE) {
        if (window.history && history.pushState) {
            historyedited = false;
            $(window).bind('popstate', function(e) {
                if (historyedited) {
                    loadProductList(location.pathname + location.search);
                }
            });
            doPager();
        }
    } else {
        
        $(window).bind('popstate', function(e) {
            loadProductList(location.pathname + location.search);
        });
        
        doPager();
    }
    function doPager() {
        $(document).on('click', '#results .paginationControl a', function(e) {
            e.preventDefault();
            overlay(); 
            $("html, body").animate({ scrollTop: 0 }, "slow");
            var href = $(this).attr('href');
            eventTrigger = $(e.target);
            loadProductList(getUrl(href));
            
            return false; 
        });
    }
    
     /*$(document).on('click', '.paginationControl a', function(e) {
        var href = $(this).attr('href');
        loadProductList(href);
        if(href != '#'){
            var str = href;
            var res = str.match(/ax/g); 
            if(res  == null){
                href = '/ax'+href; 
            } 
            loadProductList(href);
        }
        return false; 
    }); */
    
    function getUrl(url, event){
        url = (typeof url === "undefined") ? window.location.pathname : url;
        //event = (typeof event === "undefined") ? null : event;
        if(typeof event === "undefined"){
            event = null; 
        }
        
        var string = '';
        var range = '';
        var count = 0;
        var rangeCount = 0;
        var append = '';
        $('.filter-links.selected').each(function(i, obj) {
            var id = $(this).children().attr('id');
            
            if($(this).children().hasClass('range')){
                range += id+',';
                rangeCount++;
            } else {
                string += id+',';
                count++;
            }
        });
        $('input#f-tag').val(range);
        $('input#f-filter').val(string);
        
        var $form = $('form.form-filter-1'); 
        var serializedForm = $form.serialize();
        var serializedFormArr = $form.serializeArray();
        
        
        var href = url+'?'+serializedForm;
        
        if(event === 'filter-change'){ //gotopage1
            var href = href.replace(/(.*)(page\/\d*)(.*)/, "$1page/1$3");
        }
        if (!oldIE) {
            history.pushState(null, null, href);
            historyedited = true;
        }
        return href+'&a=1'; 
    }
    
    
    $(document).on('change', '.filter-options', function(e) {
        overlay(); 
        eventTrigger = $(e.target);
        loadProductList(getUrl(undefined, 'filter-change'));
    });
    
    $(document).on('click', '.clear-filters', function(e) {
        $('.category-filters .filter-links').removeClass('selected');
        $('.clear-filters').addClass('hide'); 
        overlay(); 
        getFilters(false); 
        e.preventDefault();
    }); 
    $(document).on('click', '.clear-ranges', function(e) {
        $('.range-filters .filter-links').removeClass('selected');
        $('.clear-ranges').addClass('hide'); 
        overlay(); 
        getFilters(false); 
        e.preventDefault();
    }); 
    
    $(document).on('click', '.clear-top-filter', function(e) {
        $('#f-top').val(''); 
        $('.clear-top-filter').addClass('hide');
        $('.top-list-i').removeClass('active').removeClass('btn-primary'); 
        overlay(); 
        loadProductList(getUrl(undefined, 'filter-change'));
        e.preventDefault();
    }); 
    
    // top list filters 
    $(document).on('click', '.top-list-i', function(e){
        
        if($(this).hasClass('active')){
            $('.top-list-i').removeClass('active').removeClass('btn-primary').removeClass('hide');
             
            var top = $(this).attr('data-top');
            $('#f-top').val(''); 
             
        } else {
            $('.top-list-i').removeClass('active').removeClass('btn-primary').removeClass('hide');
            $(this).addClass('active').addClass('btn-primary');
            $('.clear-top-filter').removeClass('hide'); 
            
            var top = $(this).attr('data-top');
            $('#f-top').val(top); 
        }
        
        
        
        
        overlay(); 
        loadProductList(getUrl(undefined, 'filter-change'));
        e.preventDefault();
        eventTrigger = $(e.target);
    });
    
    
    $(document).on('click', '.filter', function(e) {
        
        if(!$(this).parent('li').hasClass('disabled')){
            overlay(); 
            var item = $(this); 
            getFilters(item); 
        } 
        e.preventDefault();
    });
    
    function getFilters(item){
        
        var target;
        if($(item).parent().parent().hasClass('range-filters')){
            target = 'range-filters';
            $('.clear-ranges').removeClass('hide'); 
        }
        if($(item).parent().parent().hasClass('category-filters')){
            target = 'category-filters';
            $('.clear-filters').removeClass('hide'); 
        }
        
        if(item){
            if($(item).parent().hasClass('selected')){
                $(item).parent().removeClass('selected');
            } else {
                $(item).parent().addClass('selected');
            }; 
        }
         
        var $form = $('form.filter-form'); 
        var serializedForm = $form.serialize();
        var serializedFormArr = $form.serializeArray();
        if(item){
            var url = $(item).attr('href');
        }
        loadProductList(getUrl(undefined, 'filter-change'), target);
    }
    
    
    
    
    
    function loadProductList(href, target){
        
        $.get( href , function( data ) {
            $( "#productListContainer .product-list" ).html( data );
            $("img.lazy").lazyload({
                threshold : 600
            });
            $('.quick-view').magnificPopup({
                type: 'ajax'
            }); 
            var count = $('.total-item-count:first').text();
            $('.items-found').html(count + ' items found');
            var showList = false; 
            if(count < 20){ $('#top20').attr('disabled', 'disabled').addClass('disabled'); showList = true; } else { $('#top20').removeAttr('disabled').removeClass('disabled'); }
            if(count < 40){ $('#top40').attr('disabled', 'disabled').addClass('disabled'); } else { $('#top40').removeAttr('disabled').removeClass('disabled'); }
            if(count < 60){ $('#top60').attr('disabled', 'disabled').addClass('disabled'); } else { $('#top60').removeAttr('disabled').removeClass('disabled'); } 
            if(count < 80){ $('#top80').attr('disabled', 'disabled').addClass('disabled'); } else { $('#top80').removeAttr('disabled').removeClass('disabled'); }
            overlayClose(); 
            
             
            var selectedRanges = $('.range-filters .filter-links.selected').length;
            var selectedCategories = $('.category-filters .filter-links.selected').length;
            
            if(target === 'range-filters'){
                $('.category-filters li').attr('disabled', 'disabled').addClass('disabled').css('opacity', '0.3');
                $('.active-category').each(function() {
                   var category = $(this).attr('data-category');
                   $('.category-filters li a#'+category).parent('li').removeAttr('disabled').removeClass('disabled').css('opacity', '1');
                }); 
                $('.active-filters').each(function() {
                   var category = $(this).attr('data-range');
                   $('.range-filters li a#'+category).parent('li').removeAttr('disabled').removeClass('disabled').css('opacity', '1');
                });
            }
            if(target === 'category-filters'){
                $('.range-filters li').attr('disabled', 'disabled').addClass('disabled').css('opacity', '0.3');
                $('.active-filters').each(function() {
                   var category = $(this).attr('data-range');
                   $('.range-filters li a#'+category).parent('li').removeAttr('disabled').removeClass('disabled').css('opacity', '1');
                });
                $('.active-category').each(function() {
                   var category = $(this).attr('data-category');
                   $('.category-filters li a#'+category).parent('li').removeAttr('disabled').removeClass('disabled').css('opacity', '1');
                }); 
            }
           
            
            
            //if(!target || (selectedRanges == 0 && selectedCategories == 0)){
            if(!target){
              //  $('.range-filters li').attr('disabled', 'disabled').addClass('disabled').css('opacity', '0.3');
                $('.active-filters').each(function() {
                   var category = $(this).attr('data-range');
                   $('.range-filters li a#'+category).parent('li').removeAttr('disabled').removeClass('disabled').css('opacity', '1');
                });
                //$('.category-filters li').attr('disabled', 'disabled').addClass('disabled').css('opacity', '0.3');
                $('.active-category').each(function() {
                   var category = $(this).attr('data-category');
                   $('.category-filters li a#'+category).parent('li').removeAttr('disabled').removeClass('disabled').css('opacity', '1');
                }); 
            }
            
                $('.active-filters').each(function() {
                   var category = $(this).attr('data-range');
                   $('.range-filters li a#'+category).parent('li').removeAttr('disabled').removeClass('disabled').css('opacity', '1');
                });
                $('.active-category').each(function() {
                   var category = $(this).attr('data-category');
                   $('.category-filters li a#'+category).parent('li').removeAttr('disabled').removeClass('disabled').css('opacity', '1');
                }); 
                
                if(selectedCategories == 0){
                     $('.range-filters li a').parent('li').removeAttr('disabled').removeClass('disabled').css('opacity', '1');
                }
                if(selectedRanges == 0){
                     $('.category-filters li a').parent('li').removeAttr('disabled').removeClass('disabled').css('opacity', '1');
                }
            
            
        initProductOverlayAndButtons();
        });
    }
    
    function refineSearchView() {
        
        if ($("#plsb").is(':visible')) {
            /* Close refine search */
            
            
            $( "#plsb" ).hide( "slow", function() {
                $('.product-list').removeClass('col-sm-9').addClass('col-sm-12');
                $('#filter-p').removeClass('col-sm-12').addClass('col-sm-9');
                $('.product-list').removeClass('refine-view');
            });
        } else {
            /* Open refine search */
            $('.product-list').removeClass('col-sm-12').addClass('col-sm-9');
             $('#filter-p').removeClass('col-sm-9').addClass('col-sm-12');
             $('.product-list').addClass('refine-view');
            $( "#plsb" ).show( "slow", function() {
                
            });
        }
    }
    
    $(document).on('click', '#refine-search', function(e) {
        refineSearchView();
        productHeight(); 
        return false; 
    }); 
    
    $(document).on('click', '.set-list-view', function(e) {
        $('.product-item').removeClass('col-sm-4').addClass('col-sm-12').addClass('list-view');
        $('.list-col-image').removeClass('col-sm-12').addClass('col-sm-5');
        $('.list-col-content').removeClass('col-sm-12').addClass('col-sm-7');
        
        if ($("#plsb").is(':visible')) {
            
        } else {
            refineSearchView(); 
        }
        /* set view */
        $.ajax({
            type: "POST",
            url: "/product/setview?",
            data: { view: 'list' },
            cache: false,
            success: function(){

            }
        });
        
        
    });
    $(document).on('click', '.set-grid-view', function(e) {
        $('.product-item').removeClass('col-sm-12').addClass('col-sm-4').removeClass('list-view');
        $('.list-col-image').removeClass('col-sm-5').addClass('col-sm-12');
        $('.list-col-content').removeClass('col-sm-7').addClass('col-sm-12');
        
        /* set view */
        $.ajax({
            type: "POST",
            url: "/product/setview?",
            data: { view: 'grid' },
            cache: false,
            success: function(){

            }
        });
    });
    $(document).on('click', '.set-print-view', function(e) {
        
        $('.printview').val(1);
        $('.form-filter-1').submit();
    });
    
    
    
            
    
    
    $(function() {
        $("img.lazy").lazyload({
            threshold : 600
        });
    });
    
    
    $(document).on('click', '.adjustment-button', function(e) {
        e.preventDefault();
        var button = $(this);
        var oldVal = $(this).closest('form').find('#qty').val();
        var breakQty = $(this).closest('form').find('#break_qty').val();


        if (oldVal == '') {
            oldVal = 0;
        }
        if (button.attr('id') == 'qty-plus') {
            var newVal = parseFloat(oldVal) + parseFloat(breakQty);
        }
        if (button.attr('id') == 'qty-minus') {
            var newVal = parseFloat(oldVal) - parseFloat(breakQty);
            if (newVal < 0) {
                newVal = 0;
            }
        }

        $(this).closest('form').find('#qty').val(newVal);
        if ($('#cartonsOrdered').length) {
            $('#cartonsOrdered').text(newVal);
            $('#noOfPieces').text(parseInt(newVal) * parseInt($('#cartonQuantity').text()));
            var totalPrice = parseInt(newVal) * Number($('.price-per-c span').text());
            totalPrice = totalPrice.toFixed(2);
            $('#totalPrice').text(totalPrice);
        }

    });
    
    
    $(document).on('click', '.shortlist-overlay .addToOrder', function(e) {
        var $form = $(this).closest('form');
        var formValues = $($form).serializeArray();
        var orderQuantity = formValues[1].value; 
        if(orderQuantity > 0){
            $.ajax({
                type: "POST",
                url: '/basket/add?format=html',
                data: $form.serialize(), //#!!!!!!!
                success: function(data2) {
                    loadBasket(); 
                },
                dataType: 'html',
                // closeOnContentClick: false,
                // closeOnBgClick: false
            });
        } 
        e.preventDefault(); 
    }); 
    
    $(document).on('click', '#productListContainer .addToOrder, #details .addToOrder', function(e) {
        
        var $form = $(this).closest('form');
        var formValues = $($form).serializeArray();
        
        
        var orderQuantity = formValues[1].value; 
        if(orderQuantity > 0){

            $.ajax({
                type: "POST",
                url: '/basket/add?format=html',
                data: $form.serialize(), 
                success: function(data2) {
                   $('.text-danger').remove();//#cleanup
                   if(data2.match(/text-danger/g)){
                       $form.before(data2);
                   } else {
                        if(formValues[2].value == 1){
                            loadBasket(); 
                        }

                        $.magnificPopup.open({
                            items: {
                                src: $(data2), // Dynamically created element
                                type: 'inline'
                            },
                            type: 'inline',
                            closeOnContentClick: false,
                            closeOnBgClick: false
                        });
                        $("img.lazy").lazyload({ threshold : 600 });

                        checkItemsInBasket(); 
                    }
                },
                dataType: 'html',
                closeOnContentClick: false,
                closeOnBgClick: false
            });
        } 
        e.preventDefault(); 
        
    }); 

    
    $(document).on('click', '.close-cst', function(e){
        $.magnificPopup.close();
    });
    
    
    /*
     * On product listing and views will check to see if you already have the
     * item in the basket. Returns product code and quantity currently. 
     */
    function checkItemsInBasket(){
        var endpoint = '/basket/check?format=json';

        $.get(endpoint, function (data) {
            $.each(data.productcodes, function (productId, quantityInBasket) {
                var $productPriceElement = $('[data-product-id=' + productId + ']');
                var $itemElement = $productPriceElement.closest('.product-item');
                $itemElement.find('.in-basket').html('<a href="/basket"><strong class="in-basket-count"><span class="c-icon c-basket-white"></span>'+quantityInBasket + ' in basket</strong>');
            });
            $('.basket-count').text(data.basketCount).show();
            $('.shortlist-count').text(data.shortlistCount).show();
        }).done(function($itemElement) {
            //complete
        });
    }    
    checkItemsInBasket();
    
    $(window).scroll(function() {
        if ($(this).scrollTop()) {
            $('.c-back-to-top:hidden').stop(true, true).fadeIn();
        } else {
            $('.c-back-to-top').stop(true, true).fadeOut();
        }
        
    });
    
    $(document).on('click', '.c-back-to-top', function(){
        $('.c-back-to-top').fadeOut();
    });
    
    
    function productHeight(){ 
     
        /*var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = new Array(),
        $el,
        topPosition = 0;

        $('.product-item').each(function() {

               $el = $(this);
          topPostion = $el.position().top;

          if (currentRowStart != topPostion) {

            // we just came to a new row.  Set all the heights on the completed row
            for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
              rowDivs[currentDiv].height(currentTallest);
            }

            // set the variables for the new row
            rowDivs.length = 0; // empty the array
            currentRowStart = topPostion;
            currentTallest = $el.height();
            rowDivs.push($el);

          } else {

            // another div on the current row.  Add it to the list and check if it's taller
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);

         }

            // do the last row
             for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
               rowDivs[currentDiv].height(currentTallest);
             }

        }); */
    }
    productHeight(); 
    
    
    if($('#account_Type-Credit').is(":not(:checked)")){
        $('#fieldset-creditAccount2, #fieldset-tradeRef').css('display', 'none');
    } 
    
    $('#account_Type-Credit').click( function(){
        $('#fieldset-creditAccount2, #fieldset-tradeRef').css('display', 'block'); 
    }); 
    $('#account_Type-ProForma').click( function(){
        $('#fieldset-creditAccount2, #fieldset-tradeRef').css('display', 'none'); 
    }); 
   
    
    
   
    
    $('.to-top').click( function(){
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    }); 
    
    $(document).on('click', '.shortlist-prompt', function(e){
        $.magnificPopup.open({
            items: {
                src: $('#checkout-modal'), // Dynamically created element
                type: 'inline'
            },
            modal: true,
            width:300
        }); 
        
        return false;
    }); 
    
    $(document).on('click', '.modal-close', function(e){
        $.magnificPopup.close(); 
        return false;
    }); 
    
    $(document).on('click', '.modal-continue', function(e){
        $.magnificPopup.close();
        window.location.href = "/checkout";
        return false;
    }); 
    
    
   
    
    
    
    $(document).on('click', '.salescontact form .input-submit',function(e){
        var id = $(this).closest('form').parent().attr('id');
        
       $.ajax({
            type: "POST",
            url: "/login/validatecontactform",
            data: $(this).closest('form').serialize(),
            cache: false,
            success: function(json){
                
                var $form = $(this).closest('form');
                if(json === 'success'){
                    $('.bg-success').remove();
                    
                  
                    
                    $('#'+id).before('<p class="bg-success">Success, your mail has been sent</p>').delay(1000);
                    $('#'+id).find('input[type=text]').val('');
                    $('#'+id).find('textarea').val('');
            
                    $('#'+id+' .text-danger').remove();
                } else {
                    $('#'+id+' .text-danger').remove();
                    $('.bg-success').remove();
                    var string = '';
                    $.each(json, function(i, item) {
                        var obj = item;
                        var string = obj[Object.keys(obj)[0]]; 

                        $('#'+id+' #'+i).after('<p class="text-danger">'+string+'</div>');
                    });
                }
            }
        }); 
        e.preventDefault();
    });
    
    $(document).on('click', '.remove-from-shortlist',function(e){
        overlay();
        $.ajax({
            type: "POST",
            url: "/shortlist/remove",
            data: $(this).closest('form').serialize(),
            cache: false,
            success: function(data){
                loadProductList('/shortlist');
                checkItemsInBasket();
            }
        });
        e.preventDefault(); 
    });
    
    $(document).on('click', '.remove-from-shortlist-basket',function(e){
        
        var $item = $(this).closest('.item'); 
        var id = $(this).closest('.item').attr('id');
        var itemPosition = $("div.item").index($item);
                
        $.ajax({
            type: "POST",
            url: "/shortlist/remove",
            data: $(this).closest('form').serialize(),
            cache: false,
            success: function(data){
                checkItemsInBasket();
                listOfItems = $('#owl-carousel1 .item');
                var item;
                for(i=0; i<listOfItems.length;i++) {
                     if(listOfItems[i].id == id) {
                        var item =  i;
                     }
                }
                $("#owl-carousel1").data('owlCarousel').removeItem(itemPosition);
                if(listOfItems.length < 2){
                    $('.related').remove();
                }
            }
        });
        e.preventDefault(); 
    });
    
    $("#delivery_date").datepicker({
        showOn: "button",
        buttonImage: "/img/f/icon-calendar.png",
        buttonImageOnly: false,
        dateFormat: 'dd/mm/yy',
        minDate: 0
    });
    $('.delivery_details .ui-datepicker-trigger').click( function() {
        $('.datepickerradio').prop("checked", true);
    });
    
    $(document).on('change', 'select[name="alternate-address"]', function(e) {
        var selectVal = $(this).val(); 
        $.post('/checkout/confirm', $('.orderconfirm').serialize()+'&submit_button=Change');
        $('.d_addresses').hide();
        $('#address'+selectVal).show().removeClass('hide'); 
    }); 
    
    
    
    $('body').on('click', function (e) {
        $('[data-toggle="popover"]').each(function () {
            //the 'is' for buttons that trigger popups
            //the 'has' for icons within a button that triggers a popup
            if($(e.target).hasClass('update')){
                
            } else if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
            }
        });
    });
    $(document).on('click', '.update-item-basket .update',function(e){
        var url = $(this).closest('form').attr('action'); 
        
        
        var thisForm  = $(this).parent('form'); 
        var userinput = thisForm.children('input[name="qty"]').val();
        var breakQty = thisForm.children('input[name="break_qty"]').val(); 
        $('#custom-download-pdf').popover();
                
        var remainder = userinput % breakQty;
        if (remainder == 0 || $(e.target).hasClass('c-remove')){
            //userinput is a multiple of breakQty
            $.ajax({
                type: "POST",
                url: url,
                data: $(this).closest('form').serialize()+'&format=json',
                cache: false,
                success: function(data){
                    checkItemsInBasket();
                    loadBasket(); 
                }
            });
        
        } else {
            //userinput is not a multiple of breakQty
            
            thisForm.children('#custom-download-pdf').popover('show')
        }
        e.preventDefault(); 
    });
    
    
    $(document).on('click', '.remove-item-basket .remove',function(e){
        var url = $(this).closest('form').attr('action'); 
        $.ajax({
            type: "POST",
            url: url,
            data: $(this).closest('form').serialize()+'&format=json',
            cache: false,
            success: function(data){
                checkItemsInBasket();
                loadBasket(); 
            }
        });
        e.preventDefault(); 
    });
    
    
    $(document).on('click', '.show-offers',function(e){
        
        if($('.price-promotions-container').is(':visible')){
            $('.price-promotions-container').addClass('hide');
            $(this).html('<strong>Show special offers</strong>');
            
        } else {
            $('.price-promotions-container').removeClass('hide');
            $(this).html('<strong>Hide special offers</strong>');
        }
        $.get("/basket/offers", function(data) {
            //loadBasket('no-overlay'); 
        });
        e.preventDefault(); 
    });
    

    if($('.btn').hasClass('trigger-click')){
        window.location.href =  $( ".trigger-click" ).attr('href');
    }
    if($('.btn').hasClass('trigger-print')){
        window.print();
        e.preventDefault(); 
    }
    
    /* regester form other field*/
    if(!$('.register .element-other').hasClass('error')){
        if(!$('.register .element-other input').val()){
            $('.register .element-other').hide(); 
        }
        
    }
    $(document).on('change', '#where_Hear', function(e) {
        var selected_option = $('#where_Hear option:selected');
        if(selected_option.val() == 'other'){
            $('.element-other').show(); 
        } else {
            $('.element-other').hide(); 
        }
    }); 
    
    /*
    * for credit uploadr. 
    */
     $(document).on('click', '.select-product', function(e){
        var productCode = $(this).prev().val(); 
        var caseid = $('#case').text(); 
        $('#productCode').val(productCode); 
        $('#productCode-show').html(productCode); 
        $('.product-list').empty(); 
        $('#iframe-container').html('<iframe src="/member/credit/upload?id='+caseid+'&productCode='+productCode+'" width="100%" height="125" style="border:none;"></iframe>')
        $('#images-container').load('/member/credit/images?productCode='+productCode);
        $('.step2credit, .step3credit').removeClass('hide'); 
        e.preventDefault(); 
    });
    
    
    $(document).on('click', '.add-to-returns-list', function(e){
        
        $('.returns-form .alert').remove(); 
        var pass = true;
        var string = ''; 
        if($('#productCode').val() == '' || $('#productCode').val() == 'Select item from above'){
            pass = false; 
            string += 'Please enter a product code <br />'; 
        }
        if($('#qty').val() == ''){
            pass = false; 
            string += 'Please enter a quantity <br />'; 
        }
        if (!$.trim($("#description").val())) {
            pass = false; 
            string += 'Please enter a reason for credit / return <br />'; 
        }
        if($('#images-container').is(':empty')){
            pass = false; 
            string += 'Please upload an image of your item <br />'; 
        }
        
        if(string){
            $(this).before('<div class="alert alert-danger"><p>'+string+'</p></div>');
            e.preventDefault(); 
        }
       
    });
    
    
    //load more
        var page = 1;
        if($("#load-more").length) {
            $("#load-more").click(function (e) {
                $('#load-more').html('<img src="/img/f/466.GIF" alt="loading" width="15" height="15"/> Loading...');
                e.preventDefault();
                page++;
                
                var possiblePage = $(".pagination li.numbers").length;
                $("#loaded").append($("<div>").load('/shop-by-range/page/'+page+ ' #getranges', function( response, status, xhr ) {
                    $("img.lazy").lazyload({ threshold : 600 });
                    if(page === possiblePage){
                        $('#load-more').hide();
                    }
                    $('#load-more').text('Load More Ranges');
                }));
                
            });
        }
    
   
    $('#additional_details').keypress(function(e) {
        var tval = $('#additional_details').val(),
            tlength = tval.length,
            set = 120,
            remain = parseInt(set - tlength);
            $('.remaining-text').html('<strong>'+remain+'</strong> characters remaining (Max 120 characters)');
        if (remain <= 0 && e.which !== 0 && e.charCode !== 0) {
            $('#additional_details').val((tval).substring(0, tlength - 1))
        }
    });
    
    $('#custom-download-pdf').popover();
    $('#custom-download-pdf').on('show.bs.popover', function () {
        var url = "/member/order/eta?pdf=true";    
        $(location).attr('href',url);
        setTimeout(function(){
            $('#custom-download-pdf').popover('hide');
        }, 10000);
    });
    
    $('legend').addClass('legend');
    
    function alignTopLinks(){
        var i=0;
        $('li.tl').each(function(){
            if(i > 2){
                $(this).children('.dropdown-menu').css('right', '10px');
            }
            i++; 
        });
    }
    
    
   
});


  

define("main", function(){});

